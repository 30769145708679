import { defineType } from "sanity";

export default defineType({
  name: "sectionLayoutConfig",
  title: "Section Layout Config",
  type: "object",
  fields: [
    {
      name: "spacingTop",
      title: "Spacing Top",
      type: "string",
      description: "leave empty for default",
      options: {
        list: [
          { title: "No space", value: "none" },
          { title: "Regular", value: "regular" },
          { title: "Compact", value: "compact" },
          { title: "Condensed", value: "condensed" }
        ]
      }
    },
    {
      name: "spacingBottom",
      title: "Spacing Bottom",
      type: "string",
      description: "leave empty for default",
      options: {
        list: [
          { title: "No space", value: "none" },
          { title: "Regular", value: "regular" },
          { title: "Compact", value: "compact" },
          { title: "Condensed", value: "condensed" }
        ]
      }
    },
    {
      name: "backgroundColor",
      title: "Background Color",
      type: "string",
      description: "leave empty for default",
      options: {
        list: [
          { title: "As Previous Section", value: "previousSection" },
          { title: "White", value: "white" },
          { title: "Neutral05 (light gray)", value: "neutral05" },
          { title: "Neutral10 (gray)", value: "neutral10" },
          { title: "Titanium (dark)", value: "titanium" }
        ]
      }
    },
    {
      name: "sectionKey",
      title: "Section Key",
      type: "string",
      description:
        "Unique identifier for the section (mainly used for a/b test)"
    }
  ]
});
