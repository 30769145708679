import { defineType } from "sanity";
import AsyncCruiseDepartureDateYearList from "../../../components/AsyncCruiseDepartureDateYearList";

export default defineType({
  name: "fycLinkCTA",
  type: "object",
  title: "FYC Link CTA",
  fields: [
    {
      name: "label",
      type: "multilingualString",
      description: 'leave empty for "Discover More"'
    },
    {
      name: "type",
      type: "buttonType"
    },

    {
      name: "filterByShip",
      title: "Filter by Ship",
      type: "array",
      of: [
        {
          type: "reference",
          to: [{ type: "ship" }]
        }
      ]
    },
    {
      name: "filterByDestination",
      title: "Filter by Destination",
      type: "array",
      of: [
        {
          type: "reference",
          to: [{ type: "destination" }]
        }
      ]
    },
    {
      name: "filterByOffer",
      title: "Filter by Offer",
      type: "array",
      of: [
        {
          type: "reference",
          to: [{ type: "exclusiveOfferPerPromo" }]
        }
      ]
    },

    {
      name: "filterByCountries",
      title: "Filter by Countries",
      type: "array",
      of: [
        {
          type: "reference",
          to: [{ type: "country" }]
        }
      ]
    },
    {
      name: "filterByPorts",
      title: "Filter by Ports",
      type: "array",
      of: [
        {
          type: "reference",
          to: [{ type: "port" }]
        }
      ]
    },
    {
      name: "filterByFeatures",
      title: "Filter by Features",
      description: "Leave empty for all",
      type: "array",
      of: [{ type: "string" }],
      options: {
        list: [
          { title: "Bridge Sailings", value: "BridgeSailings" },
          { title: "Gentlemen Hosts", value: "GentlemenHosts" },
          { title: "Culinary Voyages", value: "CulinaryVoyages" },
          { title: "Wine Cruises", value: "WineCruises" }
        ]
      }
    },
    {
      name: "filterByDurations",
      title: "Filter by Durations",
      description: "Leave empty for all",
      type: "array",
      of: [{ type: "string" }],
      options: {
        list: [
          { title: "1-8 days", value: "1-8" },
          { title: "9-12 days", value: "9-12" },
          { title: "13-18 days", value: "13-18" },
          { title: "19+ days", value: "19+" }
        ]
      }
    },
    {
      name: "filterByDepartureYears",
      title: "Filter by Departure Years",
      type: "array",
      of: [{ type: "string" }],
      components: {
        input: AsyncCruiseDepartureDateYearList
      }
    }
  ],
  preview: {
    select: {
      title: "label.en",
      subtitle: "type"
    }
  }
});
