/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Box,
  Button,
  Stack,
  Text,
  Grid,
  Flex,
  Badge,
  BadgeTone,
  ButtonTone,
  ButtonProps
} from "@sanity/ui";
import {
  SFNClient,
  StartExecutionCommand,
  ListExecutionsCommand,
  SFNClientConfig,
  ExecutionListItem
} from "@aws-sdk/client-sfn";
import { DashboardWidgetContainer } from "@sanity/dashboard";
import { GiDeathSkull, GiTransform } from "react-icons/gi";
import { MdPriceCheck } from "react-icons/md";
import { FaHotel, FaShip } from "react-icons/fa";
import { ExecutionStatus } from "@aws-sdk/client-sfn/dist-types/models/models_0";

function useInterval(callback: () => void, delay: number) {
  const savedCallback = useRef<typeof callback>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current?.();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => {
        clearInterval(id);
      };
    }
  }, [callback, delay]);
}

type ButtonState = { disabled: boolean };

const awsArnPrefix = `arn:aws:states:us-east-1:${process.env.SANITY_STUDIO_AWS_ACCOUNT_ID}:stateMachine:`;
const awsConfig: SFNClientConfig = {
  region: process.env.SANITY_STUDIO_AWS_REGION,
  credentials: {
    accessKeyId: process.env.SANITY_STUDIO_AWS_ACCESS_KEY_ID!,
    secretAccessKey: process.env.SANITY_STUDIO_AWS_SECRET_ACCESS_KEY!
  }
};

const buttonToneFromStatus = new Map<string | undefined, BadgeTone>([
  ["SUCCEEDED", "positive"],
  ["RUNNING", "caution"],
  ["FAILED", "critical"],
  ["ABORTED", "critical"],
  ["TIMED_OUT", "critical"],
  [undefined, "default"]
]);

function isoDateTimeString() {
  return `${new Date()
    .toISOString()
    .slice(0, 16)
    .replace(":", "")
    .replace("T", "-")}`;
}

const startExecution = (
  executionArn: string,
  endpointFilter: string[],
  setButtonState: (value: ButtonState) => void
) => {
  setButtonState({ disabled: true });

  const client = new SFNClient(awsConfig);
  const input = {
    input: JSON.stringify({ endpointFilter }),
    name: `ExecutedFromSanityDashboard-${isoDateTimeString()}`,
    stateMachineArn: executionArn
  };
  const command = new StartExecutionCommand(input);
  client.send(command);
};

const getMostRecentExecution = (
  executionArn: string,
  setExecutions: (item: ExecutionListItem) => void
) => {
  const client = new SFNClient(awsConfig);

  const command = new ListExecutionsCommand({
    stateMachineArn: executionArn,
    maxResults: 1
  });
  client.send(command).then((response) => {
    if (response.executions) setExecutions(response.executions[0]);
  });
};

function formatDate(date: Date | undefined) {
  return date?.toLocaleDateString("en-GB", {
    year: "2-digit",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric"
  });
}

type ImportButtonProps = ButtonProps & {
  disabled: boolean;
  text: string;
  description: string;
  onClick: () => void;
  tone?: ButtonTone;
};

const ImportButton: React.FC<ImportButtonProps> = ({
  description,
  disabled,
  onClick,
  text,
  tone = "positive",
  ...props
}) => {
  return (
    <Stack width="100%">
      <Button
        fontSize={[2, 2, 3]}
        padding={[3, 3, 4]}
        text={text}
        tone={tone}
        disabled={disabled}
        onClick={onClick}
        {...props}
      />

      <Box padding={2}>
        <Text muted size={1}>
          {description}
        </Text>
      </Box>
    </Stack>
  );
};

const InitiateImport: React.FC = () => {
  const dataset = process.env.SANITY_STUDIO_AWS_ENV;
  const transformExecutionArn = `${awsArnPrefix}transform-shop-api-${dataset}`;
  const initiateImportExecutionArn = `${awsArnPrefix}initiate-import-${dataset}`;
  const importExecutionArn = `${awsArnPrefix}import-shop-api-${dataset}`;

  const [buttonState, setButtonState] = useState<ButtonState>({
    disabled: true
  });
  const [importExecution, setImportExecution] = useState<ExecutionListItem>(
    {} as ExecutionListItem
  );
  const [transformExecution, setTransformExecution] =
    useState<ExecutionListItem>({} as ExecutionListItem);

  const isNotRunning = (status : ExecutionStatus | undefined) =>  status !== "RUNNING" && status !== undefined;

  useEffect(() => {
    if (
      isNotRunning(importExecution.status) &&
      isNotRunning(transformExecution.status)
    ) {
      setButtonState({ disabled: false });
    } else {
      setButtonState({ disabled: true });
    }
  }, [importExecution.status, transformExecution.status]);

  useInterval(async () => {
    await getMostRecentExecution(importExecutionArn, setImportExecution);
    await getMostRecentExecution(transformExecutionArn, setTransformExecution);
  }, 5000);

  return (
    <DashboardWidgetContainer
      //@ts-ignore
      header={
        <div>
          Shop Api Import
          <br /> <Badge tone={"caution"}>USE WITH CAUTION</Badge>
        </div>
      }
    >
      <Stack as={"ul"}>
        <Card borderBottom as={"li"} padding={4}>
          <Grid columns={4}>
            <Box column={3}>
              <Card padding={4}>
                <Stack space={3}>
                  <Text size={2}>Import</Text>

                  <Text muted size={1}>
                    Start: {formatDate(importExecution.startDate)} Stop:{" "}
                    {formatDate(importExecution.stopDate)}
                  </Text>
                </Stack>
              </Card>
            </Box>

            <Flex justify={"center"} align={"center"}>
              <Stack space={3}>
                <Badge tone={buttonToneFromStatus.get(importExecution.status)}>
                  {importExecution.status}
                </Badge>
              </Stack>
            </Flex>

            <Box column={3}>
              <Card padding={4}>
                <Stack space={3}>
                  <Text size={2}>Transform</Text>

                  <Text muted size={1}>
                    Start: {formatDate(transformExecution.startDate)} Stop:{" "}
                    {formatDate(transformExecution.stopDate)}
                  </Text>
                </Stack>
              </Card>
            </Box>

            <Flex justify={"center"} align={"center"}>
              <Stack space={3}>
                <Badge
                  tone={buttonToneFromStatus.get(transformExecution.status)}
                >
                  {transformExecution.status}
                </Badge>
              </Stack>
            </Flex>
          </Grid>
          <hr />
          <Stack space={4} paddingTop={4} paddingBottom={4}>
            <Text size={3}>Transform only</Text>
            <Grid columns={2} gap={[1, 1, 2, 3]}>
              <ImportButton
                icon={GiTransform}
                text="Transform Only"
                description={`Use when an "Offer" or it's tokens change in Sanity`}
                disabled={buttonState.disabled}
                onClick={() => {
                  startExecution(transformExecutionArn, [], setButtonState);
                }}
              />
            </Grid>
          </Stack>
          <hr />
          <Stack space={4} paddingTop={4} paddingBottom={4}>
            <Text size={3}>Imports from Shop Api</Text>
            <Grid columns={2} gap={[1, 1, 2, 3]}>
              <ImportButton
                icon={MdPriceCheck}
                text="Import Prices & Offers"
                description={`Use to re-import prices from the Shop Api`}
                disabled={buttonState.disabled}
                onClick={() => {
                  startExecution(
                    initiateImportExecutionArn,
                    [
                      "special-voyages-v3",
                      "prices-v3",
                      "promotions",
                      "airpromos"
                    ],
                    setButtonState
                  );
                }}
              />

              <ImportButton
                icon={FaShip}
                text="Import Cruises"
                description={`Use to re-import "Cruise" data from the Shop Api`}
                disabled={buttonState.disabled}
                onClick={() => {
                  startExecution(
                    initiateImportExecutionArn,
                    ["voyages", "comboVoyages", "special-voyages-v3"],
                    setButtonState
                  );
                }}
              />

              <ImportButton
                icon={FaHotel}
                text="Import Itinerary Shorex/LP/Hotels"
                description={`Use to re-import "Shorex", "Hotel" and "Land Programme"
                  data from the Shop Api`}
                disabled={buttonState.disabled}
                onClick={() => {
                  startExecution(
                    initiateImportExecutionArn,
                    [
                      "shorexes",
                      "itinerary-shorexes",
                      "land-packages",
                      "itinerary-land-packages",
                      "land-packages-special-voyages",
                      "hotels",
                      "hotels-itinerary",
                      "hotels-special-voyages"
                    ],
                    setButtonState
                  );
                }}
              />

              <div style={{ gridColumn: "1/3" }}>
                <ImportButton
                  icon={GiDeathSkull}
                  tone="caution"
                  text="Import ALL (very long process)"
                  description={`WARNING: Use to re-import eveything from the Shop Api`}
                  disabled={buttonState.disabled}
                  onClick={() => {
                    startExecution(
                      initiateImportExecutionArn,
                      [],
                      setButtonState
                    );
                  }}
                />
              </div>
            </Grid>
          </Stack>
        </Card>
      </Stack>
    </DashboardWidgetContainer>
  );
};

export default InitiateImport;
