import type { DashboardWidget, LayoutConfig } from "@sanity/dashboard";
import { GatsbyPreview } from "./gatsby-preview";

export function gatsbyPreviewStatus(config: {
  layout?: LayoutConfig;
  url: string;
}): DashboardWidget {
  return {
    name: "gatsby-preview-status",
    component: (props) => <GatsbyPreview url={config.url} />,
    layout: config.layout ?? { width: "medium" }
  };
}
