import { defineType } from "sanity";
import { linkablePageTypes } from "../../../common/allLinkablePage";

export default defineType({
  name: "pageCTA",
  type: "object",
  title: "Page CTA",
  fields: [
    {
      name: "label",
      type: "multilingualString",
      description: 'leave empty for "Discover More"'
    },
    {
      name: "page",
      type: "reference",
      to: linkablePageTypes
    },
    {
      title: "Open in new tab",
      name: "openInNewTab",
      type: "boolean"
    },
    {
      name: "type",
      type: "buttonType"
    }
  ],
  preview: {
    select: {
      title: "label.en",
      subtitle: "type"
    }
  }
});
