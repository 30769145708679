import { ComboType } from "@silversea-ssc/dotcom-read-model-types/Cruise";
import { useQuery } from "react-query";
import { SanityClient } from "sanity";

export type SanityCruiseQuery = {
  _type?: string;
  _id?: string;
  cruiseCode: string;
  comboType?: ComboType;
  isVisible?: boolean;
  isAvailable?: boolean;
  segments?: {
    cruiseCode: string;
  }[];
};

export const useSanityCruiseData = (
  cruiseCode: string,
  client: SanityClient
) => {
  return useQuery({
    queryKey: ["sanity", cruiseCode],
    enabled: !!cruiseCode && cruiseCode.length > 0,
    queryFn: async () =>
      await client.fetch<SanityCruiseQuery>(
        `*[(_type == "cruiseData" || _type =="specialVoyageData") && cruiseCode == $cruiseCode]{
          _type,
          _id,
          cruiseCode,
          comboType,
          isVisible,
          isAvailable,
          "segments": cruises[]->{
            cruiseCode
          }
        }[0]`,
        { cruiseCode }
      )
  });
};
