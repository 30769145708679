import { useMutation, useQuery } from "react-query";
import { queryClient } from "../../components/ReactQueryProvider";

export const fetchFun = async <T>(url: string): Promise<T> => {
  const response = await fetch(url);

  if (!response.ok) throw new Error("Network response was not ok");
  return await response.json();
};

const fetchStatus = async (url: string) => {
  const apiUrl = `${url}/__preview-status?${Date.now()}`;

  try {
    const response = await fetch(apiUrl);
    if (!response.ok) {
      throw `Server error: [${response.status}] [${response.statusText}] [${response.url}]`;
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching preview status", error);
    return { status: "error" };
  }
};

type PreviewStatus = "error" | "running" | "restarting";

export const usePreviewStatus = (url: string) => {
  return useQuery({
    queryKey: ["preview-status", url],
    enabled: true,
    retry: false,
    cacheTime: 0,
    refetchInterval: 10000,
    queryFn: () =>
      fetchStatus(url).then((data): Promise<PreviewStatus> => {
        console.debug("Preview status", data.status);
        return data.status;
      })
  });
};

export const useRestartPreview = (url: string) => {
  return useMutation(triggerPreviewRestart(url), {
    onMutate: () => {
      queryClient.setQueryData(["preview-status", url], "restarting");
    }
  });
};

const triggerPreviewRestart = (url: string) => async () => {
  const apiUrl = `${url}/__preview-restart`;
  try {
    const response = await fetch(apiUrl, { method: "POST", mode: "no-cors" });
    return await response.text();
  } catch (error) {
    console.error("Error restarting preview", error);
    return { status: "error" };
  }
};
