import { ReactElement } from "react";
import { LogItem } from "./CruiseDiagnosticImporterLogTable";
import styled from "styled-components";
import { match } from "ts-pattern";
import { CruiseDiagnosticImporterLog } from "@silversea-ssc/dotcom-api-types/CruiseDiagnosticImporterLog";

const ignoreKeys = [
  "timestamp",
  "level",
  "message",
  "changes",
  "service",
  "xray_trace_id",
  "xRayTraceId",
  "_id",
  "functionName",
  "fiberId",
  "transformation",
  "cruiseCode",
  "today",
  "functionRequestId"
];

const ListItems = styled("ul")`
  padding-inline-start: 1em;
  list-style-position: inside;
`;
const ListItem = styled("li")``;

const objectToDescription = (log: LogItem) => (
  <ListItems>
    {Object.entries(log.raw)
      .filter(([key]) => !ignoreKeys.includes(key))
      .map(([key, value]) => (
        <ListItem key={key} aria-label={key + " " + value.toString()}>
          {key}: {value.toString()}
        </ListItem>
      ))}
  </ListItems>
);

const diffToDescription = (
  diff: NonNullable<CruiseDiagnosticImporterLog["changes"]>[0]
) => {
  const pathName = diff.path.join(".");

  return match(diff)
    .with({ type: "CHANGE" }, (args) => (
      <>
        {pathName} <s>{args.oldValue as string}</s> → {args.value as string}
      </>
    ))
    .with({ type: "CREATE" }, (args) => (
      <>
        + {pathName} = {args.value as string}
      </>
    ))
    .with({ type: "REMOVE" }, (args) => (
      <>
        - {pathName} = <s>{args.oldValue as string}</s>
      </>
    ))
    .exhaustive();
};

const diffsToDescription = (
  diffs: NonNullable<CruiseDiagnosticImporterLog["changes"]>
) => (
  <ListItems>
    {diffs.map((diff) => {
      const pathName = diff.path.join(".");
      return <ListItem key={pathName}>{diffToDescription(diff)}</ListItem>;
    })}
  </ListItems>
);

export function enhanceLogDescription(log: LogItem): ReactElement | string {
  return match(log.raw)
    .with({ functionName: "shop-api-importers-dev-cruiseTransformer" }, () => {
      return (
        <>
          Changes:{" "}
          {log.raw.changes
            ? diffsToDescription(log.raw.changes)
            : "no changes logged"}
        </>
      );
    })
    .otherwise(() => objectToDescription(log));
}
