import { BsGrid3X2 } from "react-icons/bs";
import { defineType } from "sanity";

export default defineType({
  title: "3. [Card] Mosaic",
  name: "cardMosaic",
  type: "object",
  icon: BsGrid3X2,
  fields: [
    {
      name: "sectionLayoutConfig",
      type: "sectionLayoutConfig",
      title: "Section Layout",
      options: {
        collapsed: true,
        collapsable: true
      }
    },
    {
      name: "heading",
      title: "Heading",
      type: "sectionTitle"
    },
    {
      name: "items",
      title: "Cards",
      type: "array",
      of: [{ type: "mosaicCard" }, { type: "mosaicCardModal" }]
    },
    {
      name: "mosaicVariant",
      description: "Layout variant for the mosaic",
      type: "string",
      validation: (Rule) => Rule.required(),
      options: {
        list: [
          { title: "4 Columns", value: "column4" },
          { title: "3 Columns", value: "column3" },
          { title: "2 (first row) + 3 Columns", value: "column2+3" },
          { title: "2 Columns", value: "column2" },
          { title: "Link Cards", value: "link-cards" }
        ]
      }
    },
    {
      name: "cardVariant",
      description: "Card variant for the mosaic",
      type: "string",
      hidden: ({ parent }) => parent?.mosaicVariant === "link-cards",
      options: {
        list: [
          { title: "Default", value: "default" },
          { title: "Immersive", value: "immersive" }
        ]
      }
    }
  ],
  preview: {
    select: {
      title: "heading.title.en",
      items: "items"
    },
    prepare({ title, items }) {
      return {
        title: title || "([Card] Mosaic)",
        subtitle: `${items.length} pages`
      };
    }
  }
});
