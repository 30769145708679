import { GoEye } from "react-icons/go";
import { defineType, TitledListValue, ValidationContext } from "sanity";
import { isPresent } from "../../utils/checks";
import { match } from "ts-pattern";

const launcherType = "launcherType" as const;
const filterByCruisesLaunch = "filterByCruisesLaunch" as const;
const filterByDestination = "filterByDestination" as const;
const title = "title" as const;

const requiredUnlessIs =
  (type: string) => (value: unknown, context: ValidationContext) => {
    const launcherTypeValue = (context.parent as any)[launcherType];
    if (launcherTypeValue === type) return true;
    return isPresent(value) ? true : "Required field";
  };

const subscribeToNewsletter = "subscribe_to_newsletter" as const;
const standardCta = "standard_cta" as const;
const cruiseCount = "cruise_count" as const;

const launcherTypeOptions: TitledListValue[] = [
  { title: "Standard CTA", value: standardCta },
  { title: "Cruise Count", value: cruiseCount },
  { title: "Subscribe To Newsletter", value: subscribeToNewsletter }
];

export default defineType({
  title: "7. Emotional Launcher",
  name: "emotionalLauncher",
  icon: GoEye,
  type: "object",
  fields: [
    {
      name: launcherType,
      title: "Type",
      type: "string",
      validation: (Rule) => Rule.required(),
      options: { list: launcherTypeOptions }
    },
    {
      name: "variant",
      title: "Variant",
      type: "string",
      options: {
        list: [
          { title: "Full (default)", value: "full" },
          { title: "Boxed", value: "boxed" }
        ]
      }
    },
    {
      name: title,
      title: "Title",
      type: "multilingualString",
      description:
        "For Subscribe To Newsletter, if empty, micro content will be used",
      validation: (Rule) => Rule.custom(requiredUnlessIs(subscribeToNewsletter))
    },
    {
      name: "body",
      title: "Body",
      type: "multilingualBlockContent",
      description:
        "For Subscribe To Newsletter, if empty, micro content will be used",
      validation: (Rule) => Rule.custom(requiredUnlessIs(subscribeToNewsletter))
    },
    {
      name: "backgroundImage",
      title: "Background Image",
      type: "image",
      description:
        "For Subscribe To Newsletter, if empty, micro content will be used",
      validation: (Rule) => Rule.custom(requiredUnlessIs(subscribeToNewsletter))
    },
    {
      type: "ctaButtons",
      name: "ctaButtons",
      title: "CTA Button",
      hidden: ({ parent }) => parent[launcherType] === subscribeToNewsletter
    },
    {
      name: filterByDestination,
      title: "Filter by Destination",
      type: "array",
      of: [
        {
          type: "reference",
          to: [{ type: "destination" }]
        }
      ],
      hidden: ({ parent }) => parent[launcherType] !== cruiseCount
    },
    {
      name: filterByCruisesLaunch,
      title: "Filter by Cruises Launch",
      type: "reference",
      to: [{ type: "cruisesLaunch" }],
      hidden: ({ parent }) => parent[launcherType] !== cruiseCount,
      weak: true
    }
  ],
  preview: {
    select: {
      launcherType: launcherType,
      title: `${title}.en`,
      filterByCruisesLaunch: `${filterByCruisesLaunch}.code`,
      filterByDestination: `${filterByDestination}.0.name.en`
    },
    prepare(document) {
      const currentLauncher = launcherTypeOptions.find(
        (x) => x.value === document.launcherType
      );

      const [title, subtitle] = match(currentLauncher)
        .with({ value: standardCta }, (value) => [document.title, value.title])
        .with({ value: cruiseCount }, (value) => {
          const filters = [
            document.filterByDestination,
            document.filterByCruisesLaunch
          ]
            .filter(isPresent)
            .join(", ");
          return [document.title, `${value.title} | ${filters}`];
        })
        .with({ value: subscribeToNewsletter }, (value) => [
          document.title ?? value.title,
          value.title
        ])
        .otherwise(() => [document.title, "Unknown Emotional Launcher"]);

      return {
        title,
        subtitle
      };
    }
  }
});
