import { DashboardWidgetContainer } from "@sanity/dashboard";
import { Badge, Box, Button, Flex, Label } from "@sanity/ui";
import { usePreviewStatus, useRestartPreview } from "./use-preview-status";
import { match } from "ts-pattern";

type GatsbyPreviewProps = {
  url: string;
};

export const GatsbyPreview: React.FC<GatsbyPreviewProps> = ({ url }) => {
  const { data: previewStatus } = usePreviewStatus(url);
  const {
    mutate: triggerPreviewRestart,
    isLoading: isPreviewRestartTriggering
  } = useRestartPreview(url);

  const toneForStatus = match(previewStatus)
    .with("error", () => "critical" as const)
    .with("running", () => "positive" as const)
    .with("restarting", () => "caution" as const)
    .otherwise(() => "default" as const);

  return (
    <DashboardWidgetContainer header="Preview server">
      <Box padding={3}>
        <Flex direction="row" align="center" justify="space-between">
          <Flex direction="row" align="center">
            <Label>Status:</Label>
            {"   "}
            <Badge tone={toneForStatus}>{previewStatus?.toUpperCase()}</Badge>
          </Flex>
          <Button
            mode="default"
            tone="default"
            disabled={
              previewStatus === "restarting" || isPreviewRestartTriggering
            }
            text="Restart"
            onClick={() => triggerPreviewRestart()}
          />
        </Flex>
      </Box>
    </DashboardWidgetContainer>
  );
};
